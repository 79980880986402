.mainContainer {
  background-image: url(../img/img-for-interval/home-mars.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.insightScreen {
  background: linear-gradient(
    90deg,
    rgba(244, 228, 222, 0.989233193277311) 0%,
    rgba(237, 195, 171, 1) 100%
  );
}

.ingenuityScreen {
  background-color: #d9b7a5;
}

.picOfDayScreen {
  background: linear-gradient(
    90deg,
    rgba(242, 218, 208, 0.989233193277311) 52%,
    rgba(218, 161, 129, 1) 100%
  );
}

.messengersScreen {
  background: rgba(201, 188, 183, 0.989) 52%;
}

.rawImagesScreen {
  background-color: #f3ede3;
}

.favoriteImagesScreen {
  background-color: #f3ede3;
}

/* insight screen */

/* background: linear-gradient(
  90deg,
  rgba(255, 157, 111, 1) 0%,
  rgba(228, 188, 172, 0.989233193277311) 54%,
  rgba(153, 133, 122, 1) 100%
); */

/* background: rgb(255, 157, 111); */
