
/* default mobile */
.footerContainer {
    background-color: var(--orange-color);
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 10px 0 10px;

}

.subscribeContainer {
    width: 90%;
    margin: 0 auto;
}

.subscribeContainer h2 {
    font-family: var(--font-lorenza);
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin: 0;
    padding: 15px 5px;
    text-align: center;
}

.subscribeContainer form {
    margin: 0;
}

.subscribeInput {
    width: 100%;
    display: flex;
    padding: 15px 0;
    
}

.subscribeInput input {
    flex-basis: 85%;
    border: none;
    border: 1px solid var(--blue-color);
}

.subscribeInput button {
    flex-basis: 15%;
    border: none;
    background-color:  rgb(14, 69, 172);
}

.subscribeContainer .formPolicy {
   font-family: var(--queen-sides-medium);
   margin: 0;
   width: 100%;
}


.logo {
    display: flex;
    margin: 0 auto;
}

p {
    font-family: "Queen-Sides", sans-serif;
    font-size: 1rem;
    font-weight: 600;
}

@media (min-width: 600px) {
    .footerContainer {
        justify-content: center;
        gap: 20px;
    }

    .footerContainer p {
        font-size: 1.3rem;
    }

    .subscribeContainer {
        width: 450px;
    }

    .subscribeContainer h2 {
        width: 450px;
        text-align: center;
    }

    .subscribeContainer form {
        padding: 15px 5px;
        width: 450px;
    }

    .subscribeInput button {
        flex-basis: 15%;
    }

    .subscribeInput input {
        flex-basis: 85%;
    }


}



