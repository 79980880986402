.picOfDayContainer {
    /* margin: 50px 0 50px 25px; */
    /* width: 300px; */
    padding: 50px 0 50px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}

.picOfDayContainer h2 {
    font-family: var(--font-lost-ages);
    text-align: center;
}
.picOfDayContainer p {
    background-color: var(--orange-color);
    opacity: 0.8;
    padding: var(--padding-light);
    border-radius: var(--border-radius);
    font-family: var(--queen-sides-light);
    font-size: 1.1rem;
    box-shadow: rgba(13, 22, 75, 0.3) 0px 19px 38px, rgba(51, 41, 189, 0.219) 0px 25px 25px;
    
    
}

.picOfDayContainer button {
    width: 150px;
    align-self: center;
    padding: var(--padding-light);
    border-radius: var(--border-radius);
    background-color: var(--blue-color);
    color: white;
    border: none;
}

.dayPicture {
    margin: 25px 0; 
}

.pictureContent {
    width: 100%;
    padding: 10px;
    border-radius: var(--border-radius);
    position: relative;
    bottom: 25px;
    
    
}

.pictureContent h2,
.pictureContent p:first-of-type {
    font-family: var(--queen-sides-light);
    background-color: var(--darker-orange);
    padding: 10px 10px;
    margin: 0;
    border-radius: var(--border-radius);
    line-height: 1.6rem;
    opacity: 1;
}

.pictureContent p:last-of-type {
    background-color: var(--darker-orange);
    padding: 10px;
    border-radius: var(--border-radius);
}

.pictureContent h2 {
    margin-bottom: 10px;
}

.buttonUp button {
    width: 50px;
    height: 50px;
    background-color: var(--orange-color);
    opacity: 0.7;
    border-radius: 50%;  
}

.picture {
    width: 100%;
    height: auto;
}

/* ------------------------------------------- */

@media (min-width: 600px) {
    .picOfDayContainer {
        width: 75%;
        height: max-content;
        padding: 150px 0 150px;
        /* margin-bottom: 100px; */
        /* margin: 50px 0 100px; */
         /* margin-bottom: calc(100px + 10vh); */
    }

    .picOfDayContainer h2 {
        font-size: 2rem;
    }

    .dayPicture {
        width: 500px;
    }

    .picOfDayResult {
        width: 500px;
        margin: 0 auto;
        padding-bottom: 40px;
        padding-top: 40px;
        border-radius: var(--border-radius);
        box-shadow: rgba(79, 129, 170, 0.3) 0px 10px 15px, rgba(105, 140, 185, 0.219) 0px 15px 15px;
    }


    .picOfDayContainer p {
        font-size: 1.4rem;
        padding: 15px 20px;
    }

    .picOfDayContainer button {
        font-size: 1.4rem;
        width: 200px;
    }
    

}

@media (min-width: 990px) {
    .picOfDayResult {
        width: 75%;
    }

    .dayPicture {
        width: 100%;
    }

    .pictureContent p:first-of-type, 
    .pictureContent p:last-of-type  {
         line-height: 1.7;
         font-size: 1.4rem;
         padding: 10px 20px;
    }

    .pictureContent h2 {
        font-size: 1.7rem;
        text-align: center;
       
    }
}

@media (min-width: 1200px) {
    .picOfDayContainer {
        width: 60%;
    }
    .picOfDayContainer h2 {
        font-size: 2.2rem;
    }
}

@media (min-width: 1300px) {
    .picOfDayResult {
        width: 70%;
    }

    .pictureContent {
        width: 95%;
        margin: 0 auto;
    }

    .dayPicture {
        width: 95%;
        margin: 0 auto;
    }
}

@media (min-width: 1400px) {
    .picOfDayResult {
        width: 950px;
    }

    .pictureContent {
        width: 900px;
    }
}

@media (min-width: 1600px) {
    .picOfDayResult {
        width: 1400px;
    }

    .dayPicture {
        display: flex;
        height: auto;
    }

    .picture {
        width: 65%;
    }

    .picture img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .pictureContent {
        width: 35%;
        /* position: unset; */
        bottom: 0;
        right: 25px;
    }

    .picOfDayContainer h2 {
        font-size: 2rem;
    }
}

