.homeContainer {
  display: flex;
  justify-content: flex-start;
  /* margin-bottom: 50px; */
  margin-bottom: calc(60px + 10vh);
  
}

.introContainer {
  width: 300px;
  margin: 50px 0 50px 20px;
  background-color: var(--orange-color);
  opacity: 0.7;
  border-radius: var(--border-radius);
  padding: var(--padding-light);
  
}

.introContainer h2 {
  font-family: var(--font-lost-ages), "sans-serif";
  text-align: center;
  margin: 0 auto;
  padding: 10px 5px;
  border-bottom: 2px dotted var(--blue-color);
}

.accordionBody {
  width: 100%;
  /* height: 10vh; */
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
}

.accordionBody p {
  font-family: var(--font-lorenza);
  font-size: 1.2rem;
  margin: 0;
}

.accordionContent {
  font-family: var(--font-lorenza);
  font-size: 1rem;
}


/* tablets 600x990 px */
@media (min-width: 600px) {
    /* .homeContainer {
      height: 80vh;
    } */

    .introContainer {
      height: max-content;
      margin-top: 70px;
      margin-left: 50px;
      width: 450px;
      margin-bottom: calc(60px + 10vh);
    }

    .introContainer h2 {
      font-size: 1.8rem;
    }

    .accordionBody p, 
    .accordionContent p {
      font-size: 1.4rem;
    }


}



@media (min-width: 990px) {
    .homeContainer {
      margin-bottom: calc(70px + 10vh);
    }
     .introContainer {
      margin-top: 120px;
     }

     .accordionBody {
      padding: 15px 10px;
    }

    .accordionContent p {
      padding: 10px 15px;
      margin: 0;
    }
}


@media (min-width: 1200px) {
    .introContainer {
      width: 500px;
      margin-left: 100px;
    }

}

@media (min-width: 1400px) {
     .introContainer {
      margin-left: 140px;
     }
}

