
.searchWrapper {
    padding: 100px 0 100px;
}

.searchContainer {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 25px 0 25px;
    border-radius: var(--border-radius);
    box-shadow: rgba(79, 129, 170, 0.3) 0px 10px 15px, rgba(105, 140, 185, 0.219) 0px 15px 15px;
}

.searchContainer h2 {
    font-family: var(--font-lost-ages);
    margin: 0;
   word-break: keep-all;
   text-align: center;
}

.searchContainer div:first-of-type {
    width: 90%;
}

.searchContainer > div {
     background-color: var(--orange-color);
     padding: 20px 10px;
      border-radius: var(--border-radius);
     
}

.searchContainer label {
    font-family: var(--font-lorenza);
    font-size: 1.1rem;
    margin: var(--padding-light);
}

.searchInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 250px;
}

.searchInputContainer select, 
.searchInputContainer input {
    border-radius: var(--border-radius);
    border-color: var(--blue-color);
    width: 200px;
    height: 30px;
    margin: 0 auto;
}

.searchInputContainer button {
    border: none;
    background-color: var(--blue-color);
    color: white;
    font-family: var(--font-lorenza);
    padding: 10px 20px;
    opacity: 1;
    border-radius: var(--border-radius);
    width: 200px;
    height: 30px;
    text-align: center;
}

.boxWrapper {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 50px;
}

.rawImagesBox {
    background-color: #e9f3fd;
    border-radius: var(--border-radius);
    box-shadow: rgba(79, 129, 170, 0.3) 0px 10px 15px, rgba(105, 140, 185, 0.219) 0px 15px 15px;
    width: 90%;
    margin: 0 auto;
    
  }

.rawImagesContainer {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    margin: 10px 0 20px;
}

.rawImagesTitle {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.rawImagesContainer p {
    font-family: var(--queen-sides-light);
    font-size: 1.1rem;
    margin: 0;
    padding: var(--padding-light);
}

.rawImage {
    width: 100%;
    height: 50vh;
    margin: 0 auto;
}

.rawImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

 /* .rawImagesBox {
  background-color: #e9f3fd;
  border-radius: var(--border-radius);
  box-shadow: rgba(79, 129, 170, 0.3) 0px 10px 15px, rgba(105, 140, 185, 0.219) 0px 15px 15px;
  width: 90%;
  margin: 0 auto;
}  */

.rawImagesList  {
    list-style: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    gap: 5px;
}

.rawImagesList li {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-family: var(--queen-sides-medium);
    cursor: pointer;
}

.rawImagesList li:hover {
    background-color: rgb(243, 226, 207);
    border-radius:50%;
}

.rawImagesList button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: var(--orange-color);
    border: none;
    cursor: pointer;
}

.activePage {
    background-color: var(--orange-color);
    border-radius: 50%;
}

/* ------------------------------------------------------- */

@media (min-width: 390px) {
    .searchContainer div:first-of-type {
        width: 350px;
    }

    .searchContainer {
        width: 390px;
        padding-bottom: 100px;
        padding-top: 50px;
    }
    .rawImagesBox {
        width: 85%;
        padding-bottom: 20px;
        
    }

    
}

/* ---------------------------------------------------------- */
@media (min-width: 600px) {
    .searchContainer {
        width: 400px;
        /* padding-bottom: 150px; */
    }

    .rawImagesBox {
        width: 75%;
    }

    .rawImagesList button {
        width: 40px;
        height: 40px;
    }
    .rawImagesList li {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
}


/* ----------------------------------------------------------- */
@media (min-width: 990px) {
    .searchContainer {
        width: 400px;
    }
    .rawImagesBox {
        width: 90%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        
    }

    .rawImagesContainer {
        flex-basis: 40%;
        border: 1px solid #72afec;
        border-radius: var(--border-radius);
    }

}

/* --------------------------------------------------------------------- */

@media (min-width: 1400px) {
    .rawImagesBox {
        width: 1250px;
    }
}

@media (min-width: 1600px) {
    .rawImagesBox {
        width: 1500px;
       
    }
    .rawImagesContainer {
        width: 400px;
        flex-basis: 30%;
    }
}















