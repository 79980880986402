
.accountWrapper {
    /* margin: 50px 0 50px; */
    margin: 0 auto;
    width: 90%;
    height: auto;
    padding: 50px 0 150px;
}



.account {
    width: 100%;
    margin: 0 auto;
    background-color: var(--verylight-blue);
    
    opacity: 1;
    border-radius: var(--border-radius);
}
.accountLinks {
    width: 100%;
    display: flex;
}

.accountLinks a {
    text-decoration: none;
    flex-basis: 50%;
    text-align: center;
    text-decoration: none;
    color: rgb(23, 77, 179);
    font-weight: bolder;
    padding: 15px 0;
    font-family: var(--font-lorenza);
}



.greeting {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.greeting h3 {
    font-family: var(--font-lorenza);
    margin: 0;
    padding: 15px 5px;
}

.greeting button {
    /*  with svg icon  these rules applies: */
    /* border: none;
    background: inherit;
    width: 45px;
    height: 45px; */
    cursor: pointer;
    background-color: red;
    color: white;
    border: none;
    border-radius: var(--border-radius);
    height: 30px;
    width: 75px;
}

.personalInfo, .customerAddress, .editLink {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
}

.personalInfo h4, .customerAddress h4 {
    margin: 0;
    padding: 10px 0;
    font-family: var(--font-lost-ages);
}

.personalInfo small {
    font-size: 0.75rem;
    font-family: var(--font-lost-ages);
}

.personalInfo p, .customerAddress p {
    margin: 0;
}

.personalInfo {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.editLink a {
    display: block;
    text-align: center;
    text-decoration: none;
    background-color: rgb(23, 77, 179);
    color: white;
    width: 50%;
    padding: 10px 5px;
    margin: 0 auto;
    border-radius: var(--border-radius);
    font-family: var(--font-lorenza);
}

.unlogedUser {
    margin: 50px 0;
    width: 100%;
    padding: 50px 0 50px;
    display: grid;
    place-items: center;
}

.unlogedUser p {
    padding: 50px 25px;
    font-size: 1.2rem;
    font-family: var(--font-lorenza);
    background-color: var(--orange-color);
    border-radius: var(--border-radius);
}

/* --------------------------------------------------- */

@media (min-width: 600px) {

    .account {
        width: 520px;
        margin-left: 0;
    }
    .personalInfo p, .customerAddress p {
        font-size: 1.2rem;
    }

    .greeting h3 {
        font-size: 1.5rem;
    }

    .accountLinks a {
        font-size: 1.2rem;
    }

    .unlogedUser {
        margin: 120px 0;
    }
}

@media (min-width: 990px) {
    
    .account {
        width: 520px;
        margin-left: 0;
    }
    .personalInfo p, .customerAddress p {
        font-size: 1.4rem;
    }
    .greeting h3 {
        font-size: 1.7rem;
    }
    .unlogedUser p {
        font-size: 1.5rem;
    }
}

@media (min-width: 1200px) {
    
}

@media (min-width: 1400px) {
    .accountWrapper {
        width: 1100px;
    }
}

