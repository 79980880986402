.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
   
} 


.headerContainer h1 {
    font-family: var(--font-lost-ages), sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0;
    
}

.navigation {
    display: flex;
    flex-direction: column;
    /* width: 350px; */
}

.navigation img {
    width: 40px;
    border: none;
    background: none;
    align-self: flex-end;
  }

  .navigation nav {
    display: flex;
    flex-direction: column;
    width: 350px;
    background-color: var(--orange-color);
    border-radius: var(--border-radius);
    /* -------------------------------------- */
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 300px;
    height: 100vh;
  }

  nav a {
    text-decoration: none;
    color: black;
    font-family: var(--font-lorenza);
    font-size: 1.2rem;
    height: 8vh;
    padding: 15px 20px;
    font-weight: 600;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
  }

  .navigation a:first-of-type {
    color: var(--blue-color);
    font-size: 1.4rem;
  }

  nav a img {
    height: 40px;
  }

  .navigation button {
    background: none;
    border: none;
  }
  /* bring back  these 2 rules for navigation on hover */

/* .navigation nav {
    display: none;
} */

/* .navigation:hover img ~ nav {
    display: flex;
} */





/* -------------------------------------------------------- */

@media (min-width: 600px) {
  .headerContainer {
    width: 90%;
    margin: 0 auto;
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
  }

  .headerContainer h1 {
    width: 300px;
    font-size: 2rem;
    font-family: var(--font-lost-ages), sans-serif;
    margin: 0;
  }

  .navigation nav {
    width: 50%; 
    
  }

  nav a {
    font-size: 1.4rem;
    margin-left: 10px;
    
  }

 

  
}

/* --------------------------------------------------------------------- */

@media (min-width: 990px) {
  .headerContainer {
    width: 100%;
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    height: 70px;
  }

  .headerContainer h1 {
    font-size: 2rem;
    margin: 0;
    width: 250px;
  }

  nav a {
    font-size: 1rem;
  }

  .navigation  img {
    display: none;
  }

  /* ------------------------- */
  nav a:last-of-type img {
    display: initial;
  }
/* ------------------------------- */
  

  .navigation {
    /* width: 80%; */
    display: flex;
    justify-content: space-around;
    position: unset;
    height: auto;
  }
  .navigation button {
    display: none;
  }

  .navigation nav {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-around;
    background-color: unset;
    width: 100%;
    position: unset;
    height: auto;
    /* flex-basis: 100%;  */
  }

  nav a {
    text-decoration: none;
    color: black;
    font-family: var(--font-lorenza);
    margin: 0;
    padding: 5px 10px;
    text-align: center;
    vertical-align: middle;
    font-size: 1.1rem;
    height: unset;
    background-color: var(--orange-color);
    border-radius: var(--border-radius);
  }

  .navigation nav:hover {
    position: unset;
  }
  
}


/* ------------------------------------------------------------------------- */
@media (min-width: 1200px) {
  .headerContainer {
    width: 90%;
  }
}

@media (min-width: 1400px) {
  .headerContainer {
    width: 1350px;
  }
}
