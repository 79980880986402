.favoriteImagesWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px 0 50px;
}

.favoriteImagesWrapper h2 {
    font-family: var(--font-lorenza);
    font-size: 1.5rem;
    margin: 0;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
}

.favoriteImagesBox {
    width: 90%;
    margin: 0 auto;
   
   
  }

  .favoriteImagesContainer {
    background-color: #e9f3fd;
    border-radius: var(--border-radius);
    box-shadow: rgba(79, 129, 170, 0.3) 0px 10px 15px, rgba(105, 140, 185, 0.219) 0px 15px 15px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    margin: 10px 0 20px;
    gap: 10px;
}

.favoriteImagesTitle {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.controlButton, 
.removeButton {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}



.controlButton button,
.removeButton button {
    width: 75px;
    height: 35px;
    border-radius: var(--border-radius);
    border: none;
    color: white;
    cursor: pointer;
    background: transparent;
}

.removeButton button {
    background: #fc037b;
}


.favoriteImagesTitle p {
    font-family: var(--queen-sides-light);
    font-size: 1.1rem;
    margin: 0;
    padding: var(--padding-light);
}

.controlButton p {
    font-size: 1.3rem;
    font-weight: bolder;
}

.favoriteImage {
    width: 100%;
    height: 50vh;
    /* height: auto; */
    margin: 0 auto;
}

.favoriteImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.noFavorites {
    width: 100%;
    height: 75vh;
    display: grid;
    place-items: center;
}

.noFavorites p {
    font-size: 1.5rem;
    font-family: var(--queen-sides-light);
    margin: 0;
    text-align: center;
    text-transform: uppercase;
}

@media (min-width: 390px) {
   .favoriteImagesBox {
    width: 85%;
    padding-bottom: 20px;
   }

}

@media (min-width: 700px) {
     .favoriteImagesBox {
        width: 90%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .favoriteImagesContainer {
        flex-basis: 45%;
        border: 1px solid #bfdbf7;
        border-radius: var(--border-radius);
    }
}

@media (min-width: 1100px) {

    .favoriteImagesBox {
        width: 90%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        
    }

    .favoriteImagesContainer {
        flex-basis: 32%;
        border: 1px solid #bfdbf7;
        border-radius: var(--border-radius);
    }

}

@media (min-width: 1400px) {
    .favoriteImagesBox {
        width: 1250px;
    }
}

@media (min-width: 1600px) {
    .favoriteImagesBox {
        width: 1500px;
       
    }
    .favoriteImagesContainer {
        width: 400px;
        flex-basis: 30%;
    }
}