.formContainer {
    width: 90%;
    margin: 0 auto;
    height: auto;
    padding: 50px 0 150px;
    
}

form {
    width: 100%;
    height: auto;
    /* margin: 50px 0 150px; */
    background-color: var(--orange-color);
    opacity: 0.8;
    border-radius: var(--border-radius);
}

.formContainer .update {
    background-color: var(--blue-color);
}

.formLinks {
    width: 100%;
    display: flex;
}
.formLinks a {
    flex-basis: 50%;
    text-align: center;
    text-decoration: none;
    color: rgb(23, 77, 179);
    font-weight: bolder;
    padding: 15px 0;
    
}

.underlineLink {
    border-bottom: 2px dotted var(--blue-color);
}

.formInputs {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    align-items: center;
}

.formInputs input, .formInputs label {
    width: 90%;
    height: 35px;
}

.formInputs label {
    padding: 5px 0;
    margin: 5px 0;
}

.formInputs input {
    border-radius: var(--border-radius);
    border-color: var(--blue-color);
}

.passwordEye {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.passwordEye button {
    background-color: inherit;
    cursor: pointer;
    border: none;
}

.submitButton {
    width: 90%;
    margin: 0 auto;
}

.submitButton button {
    width: 100%;
    height: 35px;
    border-radius: var(--border-radius);
    background-color:  rgb(27, 78, 172);
    color: white;
    text-transform: uppercase;
    margin: 15px 0;
}

.formPolicy {
    width: 90%;
    margin: 0 auto;
}

.formPolicy p {
    font: var(--font-lorenza);
    text-align: center;
    margin: 0;
    padding: 15px 0;
    line-height: 1.5rem;
}

.formPolicy a {
    text-decoration: none;
}

.registerButtons {
    display: flex;
    width: 90%;
    margin: 0 auto;
}

.registerButtons button {
    flex-basis: 50%;
    height: 35px;
    margin: 10px 5px;
    border-radius: var(--border-radius);
    background-color:  rgb(27, 78, 172);
    color: white;
    text-transform: uppercase;
}

.forgotPasswordLink {
    width: 90%;
    height: auto;
    margin: 0 auto;
    padding: 10px 0;
}

.forgotPasswordLink a {
    text-decoration: none;
    color: rgb(27, 78, 172);
}

.toggleSubscribeBox {
    /* border: 1px solid black; */
    width: 100%;
    margin: 20px 0 10px;
    height: auto;
    background-color: var(--orange-color);
    opacity: 0.8;
    border-radius: var(--border-radius);
}

.toggleSubscribeBox h3 {
    font-family: var(--font-lorenza);
    margin: 0 auto;
    padding: 15px 5px;
    text-align: center;
}

.toggleSubscribeBox form {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 10px 0;
}


.subscriberBox {
    display: flex;
    width: 90%;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
    padding: 5px 10px;
}

.subscriberBox label {
    width: 80%;
    font-family: var(--font-lorenza);
    font-size: 1.2rem;
    color:rgb(15, 53, 126);
    font-weight: bolder;
}

.subscriberBox input {
    width: 10%;
    margin: 5px 0;
    border-radius: 15px;
    background-color:rgb(15, 53, 126);
    height: 25px;
    
}

/* ---------------------------------------------------- */

@media (min-width: 600px) {

    form {
        width: 520px;
    }

    .toggleSubscribeBox {
        width: 520px;
    }

}

@media (min-width: 990px) {
    .formContainer {
        width: 95%;
    }
}

@media (min-width: 1200px) {
    .formContainer {
        width: 90%;
    }
}

@media (min-width: 1400px) {
    .formContainer {
        width: 1100px;
    }
}

