

.bestImages {
    display: flex;
    flex-direction: column;
    background-color: var(--verylight-blue);
}

.bestImages h2 {
    font-family: var(--font-lorenza);
    text-align: center;
}

.imageSliderContainer {
    display:flex;
    flex-direction: column;
    position: relative;
}

.slideImage {
    width: 90%;
    height: 50vh;
    margin: 0 auto;
}

.slideImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.sliderButtons {
    width: 100%;
    display: flex;   
    justify-content: space-between;
    position: absolute;
    /* top: 200px;
    height: 50px; */
    top: 150px;
    height: 50px;
}

.sliderButtons button {
    border: none;
    border-radius: 50px;
    background-color: var(--orange-color);

}

.heartButton {
    /* align-self: self-end;
    position: relative;
    top: 120px; */
    border-bottom: none;
}

.heartButton button {
    border: none;
    background-color: unset;
    width: 45px;
    height: 45px;
    cursor: pointer;
}

.imageDescription {
    width: 90%;
    background-color: var(--verylight-blue);;
    margin: 0 auto;
    padding: var(--padding-light);
    position: relative;
    bottom: 30px;
    border-radius: var(--border-radius);
    border: 1px dotted var(--blue-color);
}

.imageTitle {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px dotted var(--blue-color);
}

.imageDescription h3 {
    font-family: var(--font-lorenza);
    font-size: 1.3rem;
    /* border-bottom: 1px dotted var(--blue-color); */
}

.imageDescription p {
    font-family: var(--queen-sides);
    font-size: 1.1rem;
}

/* ----------------------------------------------------------- */

@media (min-width: 600px) {
    .bestImages h2 {
        font-size: 1.7rem;
        /* margin-bottom: 0; */
    }
    
    .slide p {
        text-align: center;
        font-size: 1.2rem;
    }

    .sliderButtons {
        top: 200px;
    }

    .imageSliderContainer {
        box-shadow: rgba(13, 22, 75, 0.3) 0px 19px 38px, rgba(51, 41, 189, 0.219) 0px 25px 25px;
        border-radius: var(--border-radius);
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

@media (min-width: 750px) {
    .imageSliderContainer {
        width: 90%;
        margin: 0 auto;
        justify-content: center;
        margin-bottom: 50px;
        
    }

    .imageTitle h3 {
        font-size: 1.5rem; ;
    }

    .slideImage {
        height: 60vh;
    }
   
   .sliderButtons {
    top: 225px;
   }

}

@media (min-width: 900px) {
    .imageSliderContainer {
        width: 80%;
        max-width: 750px;
    }

    .sliderButtons {
        top: 225px;
        left: 20px;
        width: 95%;
        
    }
}

@media (min-width:1100px) {
    .imageSliderContainer {
        max-width: 900px;
    }

    .sliderButtons {
        top: 240px;
    }
}


@media (min-width:1400px) {

    .bestImages h2 {
        font-size: 2.2rem;
    }


}

